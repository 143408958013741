//
// Toggle
//

.toggle {
	&.collapsible:not(.collapsed),
  // Integration with Bootstrap Collapse
  &.active {
		.toggle-off {
			display: none;
		}
	}

	&.collapsible.collapsed,
  // Integration with Bootstrap Collapse
  &:not(.collapsible):not(.active) {
		.toggle-on {
			display: none;
		}
	}
}
