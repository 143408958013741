//
// Fixed
//

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.fixed-top#{$infix} {
			position: fixed;
			z-index: get($fixed, z-index);
			top: 0;
			left: 0;
			right: 0;
		}
	}
}
