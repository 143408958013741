//
// Theme style
//
// Mode
$mode: default;
// Initialize
@import 'init';
// Components
@import './core/components/components';
@import 'components/components';
// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';
.learn-more {
    border: 2px solid #ccc;
    padding: .35rem 15px;
    background-color: white;
    border-radius: 8px;
    color: #888383;
    font-weight: 600;
    margin-bottom: 10px;
    &:hover {
        opacity: .8;
    }
}