//
// Text
//

// Text colors
@each $name, $color in $theme-text-colors {
	// Base color
	.text-#{$name} {
		color: $color !important;
	}

	// Inverse color
	@if (theme-inverse-color($name)) {
		.text-inverse-#{$name} {
			color: theme-inverse-color($name) !important;
		}
	}

	@if (theme-light-color($name)) {
		// Light colors
		.text-light-#{$name} {
			color: theme-light-color($name) !important;
		}
	}

	// Hover
	.text-hover-#{$name} {
		transition: $transition-link;

		i {
			transition: $transition-link;
		}

		&:hover {
			transition: $transition-link;
			color: $color !important;

			i {
				transition: $transition-link;
				color: $color !important;
			}

			.svg-icon {
				color: $color !important;
			}
		}
	}

	// Hover inverse
	@if (theme-inverse-color($name)) {
		.text-hover-inverse-#{$name} {
			transition: $transition-link;

			i {
				transition: $transition-link;
			}

			&:hover {
				transition: $transition-link;
				color: theme-inverse-color($name) !important;

				i {
					transition: $transition-link;
					color: theme-inverse-color($name) !important;
				}

				.svg-icon {
					color: theme-inverse-color($name) !important;
				}
			}
		}
	}

	// Active
	.text-active-#{$name} {
		transition: $transition-link;

		i {
			transition: $transition-link;
		}

		&.active {
			transition: $transition-link;
			color: $color !important;

			i {
				transition: $transition-link;
				color: $color !important;
			}

			.svg-icon {
				color: $color !important;
			}
		}
	}

	// Active inverse
	@if (theme-inverse-color($name)) {
		.text-active-inverse-#{$name} {
			transition: $transition-link;

			i {
				transition: $transition-link;
			}

			&.active {
				transition: $transition-link;
				color: theme-inverse-color($name) !important;

				i {
					transition: $transition-link;
					color: theme-inverse-color($name) !important;
				}

				.svg-icon {
					color: theme-inverse-color($name) !important;
				}
			}
		}
	}
}

// Text transparent
.fw-boldest {
	font-weight: $font-weight-boldest !important;
}

// Text transparent
.text-transparent {
	color: transparent;
}

// Cursor pointer
.cursor-pointer {
	cursor: pointer;
}

// Cursor default
.cursor-default {
	cursor: default;
}

// Cursor move
.cursor-move {
	cursor: move;
}

// Icons
i {
	line-height: 1;
	font-size: 1rem;
	color: $text-muted;
}

// Link transition
a {
	transition: $transition-link;

	&:hover {
		transition: $transition-link;
	}
}
