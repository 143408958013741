//
// Bullet
//

// Base
.bullet {
	display: inline-block;
	background-color: get($bullet, bg-color);
	@include border-radius(get($bullet, bar-border-radius));
	width: get($bullet, bar-width);
	height: get($bullet, bar-height);
	flex-shrink: 0;
}

// Dot bullet
.bullet-dot {
	width: get($bullet, dot-size);
	height: get($bullet, dot-size);
	border-radius: 100% !important;
}

// Vertical bullet
.bullet-vertical {
	width: get($bullet, bar-height);
	height: get($bullet, bar-width);
}

// Vertical line
.bullet-line {
	width: get($bullet, line-width);
	height: get($bullet, line-height);
	border-radius: 0;
}
